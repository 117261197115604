// Header Buttons

// .header-btn-lg {
.right-header-btn-lg {
  // padding: 0 0 0 ($layout-spacer-x);
  // margin-left: ($layout-spacer-x);
  // margin-right: $grid-gutter-width;
  position: fixed;
  // right: $grid-gutter-width;
  // top: $grid-gutter-width;
  right: 15px;
  bottom: 15px;
  // top: 15px;
  z-index: 1010;
  height: 50px;
  width: 50px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  // display: flex;
  // align-items: center;
  // position: relative;

  // &::before {
  //   position: absolute;
  //   left: -1px;
  //   top: 50%;
  //   background: $border-color;
  //   width: 1px;
  //   height: 30px;
  //   margin-top: -15px;
  //   content: '';
  // }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background: $gray-600;
  }
}

.left-header-btn-lg {
  // padding: 0 0 0 ($layout-spacer-x);
  // margin-left: ($layout-spacer-x);
  // margin-right: $grid-gutter-width;
  position: fixed;
  left: 15px;
  bottom: 15px;
  // top: 15px;
  // left: $grid-gutter-width;
  // top: $grid-gutter-width;
  z-index: 1010;
  height: 50px;
  width: 50px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background: $gray-600;
  }
}
