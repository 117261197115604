::-webkit-scrollbar {
  width: 0px; /* remove scrollbar space */
  background: transparent;
  height: 0;
  /* optional: just make scrollbar invisible */
}
/* optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  /* background: #ff0000; */
  height: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #f1f4f6 !important;
}

button,
input {
  font-family: Roboto !important;
  font-size: inherit;
  font-size: 14px !important;
  /* font-weight: 400 !important; */
  /* letter-spacing: normal; */
  /* line-height: 19px; */
}

h1,
h2,
h3,
h4,
h5,
p,
li,
a {
  font-family: inherit;
}
